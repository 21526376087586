/*
* demo.css
* File include item demo only specific css only
******************************************************************************/

.light-style .menu .app-brand.demo {
    height: 64px;
  }
  
  .dark-style .menu .app-brand.demo {
    height: 64px;
  }
  
  .app-brand-logo.demo {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -ms-flexbox;
    display: flex;
    width: 34px;
    height: 24px;
  }
  
  .app-brand-logo.demo svg {
    width: 35px;
    height: 24px;
  }
  
  .app-brand-text.demo {
    font-size: 1.375rem;
  }
  
  /* ! For .layout-navbar-fixed added fix padding top tpo .layout-page */
  .layout-navbar-fixed .layout-wrapper:not(.layout-without-menu) .layout-page {
    padding-top: 64px !important;
  }
  .layout-navbar-fixed .layout-wrapper:not(.layout-horizontal):not(.layout-without-menu) .layout-page {
    padding-top: 78px !important;
  }
  /* Navbar page z-index issue solution */
  .content-wrapper .navbar {
    z-index: auto;
  }
  
  /*
  * Content
  ******************************************************************************/
  
  .demo-blocks > * {
    display: block !important;
  }
  
  .demo-inline-spacing > * {
    margin: 1rem 0.375rem 0 0 !important;
  }
  
  /* ? .demo-vertical-spacing class is used to have vertical margins between elements. To remove margin-top from the first-child, use .demo-only-element class with .demo-vertical-spacing class. For example, we have used this class in forms-input-groups.html file. */
  .demo-vertical-spacing > * {
    margin-top: 1rem !important;
    margin-bottom: 0 !important;
  }
  .demo-vertical-spacing.demo-only-element > :first-child {
    margin-top: 0 !important;
  }
  
  .demo-vertical-spacing-lg > * {
    margin-top: 1.875rem !important;
    margin-bottom: 0 !important;
  }
  .demo-vertical-spacing-lg.demo-only-element > :first-child {
    margin-top: 0 !important;
  }
  
  .demo-vertical-spacing-xl > * {
    margin-top: 5rem !important;
    margin-bottom: 0 !important;
  }
  .demo-vertical-spacing-xl.demo-only-element > :first-child {
    margin-top: 0 !important;
  }
  
  .rtl-only {
    display: none !important;
    text-align: left !important;
    direction: ltr !important;
  }
  
  [dir='rtl'] .rtl-only {
    display: block !important;
  }
  
  /*
  * Layout demo
  ******************************************************************************/
  
  .layout-demo-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 1rem;
  }
  .layout-demo-placeholder img {
    width: 900px;
  }
  .layout-demo-info {
    text-align: center;
    margin-top: 1rem;
  }
  